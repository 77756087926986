import { Box, Grid, MenuItem, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AccessPermission,
  ActivatedLicensePacketInput,
  AppStateSimpleInput,
  AsAppRoleInput,
  AssignedRoleInput,
  MinimalUserInfo,
  //OrganizationRoleEx,
  SingleUserLicenseInput,
  UserAccessPermission,
  UserResourcePermission,
  UserResourcePermissionEx,
  UserResourcePermissionExUser,
} from '../../../../api/iam.models'
import { ActionWithDescription, useAuthContext } from '../../../components/partials'
import { getTranslatedValue, getUserName } from '../../../helpers/utils'
import { useAccessPermissions, useFetchSubordinates } from '../../../hooks/userHooks'
import { ActionType } from '../UserContext/userActions'
import { useManageUserContext } from '../UserContext/UserProvider'
import SubordinatePermissions from './SubordinatePermissions'
import ApplicationLicenses from './ApplicationLicenses'
import { CsButtonSecondary } from '@csinstruments/cs-react-theme'
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined'
import { SelectedSingleUserLicense } from '../UserContext/initialState'
export type UserExpertPermissionsProps = {
  selectedSingleUserLicInfo?: SelectedSingleUserLicense
  superiorInfo?: MinimalUserInfo
  updateSuperiorInfo?: (supInfo: MinimalUserInfo) => void
  subordinatesInfo?: MinimalUserInfo[]
  selectedAssignableLicense: SelectedSingleUserLicense[]
  updatedSelAssignLicense: (asl: SelectedSingleUserLicense[]) => void
  updateSubordinatesInfo?: (userInfo: MinimalUserInfo[]) => void
}

const UserExpertPermissions: React.FC<UserExpertPermissionsProps> = ({
  selectedSingleUserLicInfo,
  updatedSelAssignLicense,
  selectedAssignableLicense,
  superiorInfo,
  updateSuperiorInfo,
}: UserExpertPermissionsProps) => {
  const { state, dispatch, editUserId } = useManageUserContext()
  //const [fetched, setFetched] = useState<boolean>(false)
  const { username } = useAuthContext()
  const [showSubPermissions, setshowSubPermissions] = useState<boolean>(false)
  const [superiorOptions, setSuperiorOptions] = useState<UserResourcePermissionExUser[] | UserResourcePermission[]>([])
  const [subordinateUsersAvail, setsubordinateUsersAvail] = useState<MinimalUserInfo[]>([])
  const [iamRoleOptions, setIamRoleOptions] = useState<UserAccessPermission[]>([])
  const [assignAccessOptions, setAssignAccessOptions] = useState<AccessPermission[]>([])
  const [superior, setSuperior] = useState<string>('')
  const { t } = useTranslation('common')

  const singleUserLicenses = React.useMemo((): SingleUserLicenseInput[] => {
    if (state.licenses.length)
      return state.licenses.map((lp) => ({
        licensePacketID: lp.licensePacketID,
        licenseGroupID: lp.licenseGroupID,
        licenseTypeID: lp.licenseTypeID,
        regularLicense: lp.regularLicense,
      }))
    return []
  }, [state.licenses])

  //console.log({ state })
  // const appName = currIAMAppState.clientName

  const appLicenseVar = React.useMemo(() => {
    return {
      appLicenseInput: state.licenses.map((lt) => ({
        appName: lt?.licenseGroupID ?? '',
        licenseTypes: [lt?.licenseTypeID ?? ''],
      })),
      licenseType: state.licenses.map((lt) => lt?.licenseTypeID ?? '') as [string],
    }
  }, [state.licenses])

  // const assignedIAMRoleInput = React.useMemo(() => {
  //   return state?.appStates
  //     ?.find((ap) => ap?.clientName === 'iam')
  //     ?.roles.map((rl) => ({
  //       appName: 'iam',
  //       roles: rl?.roleName ? [rl?.roleName] : [],
  //     })) as AssignedRoleInput[]
  // }, [state.appStates])

  const { superiorUserOptions } = useAccessPermissions({
    licenses: singleUserLicenses,
    superiorName: state.superior?.orgUserID ?? '',
    editUserId: editUserId,
  })

  //const { fetchSubordinates, subOdinateUsers } = useFetchSubordinates()

  useEffect(() => {
    if (superiorUserOptions?.length) {
      //console.log({ superiorUserOptions })
      const tempSuperiors = editUserId
        ? superiorUserOptions.filter((u) => u.userInfo.orgUserID != editUserId)
        : superiorUserOptions
      setSuperiorOptions(tempSuperiors)
      if (!state.superior?.orgUserID) {
        const defaultSuperiorUserResourcePermission = superiorUserOptions.find((u) => u.userInfo.email === username)
        setSuperior(defaultSuperiorUserResourcePermission?.userInfo.orgUserID ?? '')

        const defaultIamRoleOptions = defaultSuperiorUserResourcePermission?.assignableUserPermission ?? []
        //console.log({ defaultIamRoleOptions })
        setIamRoleOptions(defaultIamRoleOptions)

        const defaultSubOrdniateUsers = defaultSuperiorUserResourcePermission?.subordinateUsers.length
          ? defaultSuperiorUserResourcePermission?.subordinateUsers
          : []
        setsubordinateUsersAvail(defaultSubOrdniateUsers)
        if (defaultIamRoleOptions.length) {
          const defaultUserPermission =
            selectedSingleUserLicInfo?.licenseTypeName != 'guest'
              ? defaultIamRoleOptions[0].access
              : defaultIamRoleOptions.find((op) => op.access.roleID === 'none-id')?.access
          const defUserAssigPerms =
            defaultIamRoleOptions.find((rl) => rl.access.roleID === defaultUserPermission?.roleID)
              ?.assignPermissionDeps ?? []
          //console.log({ defaultUserPermission }, { defUserAssigPerms })
          dispatch({
            type: ActionType.EditUserSimpleInput,
            payload: {
              ...state,
              superior: {
                orgUserID: defaultSuperiorUserResourcePermission?.userInfo.orgUserID ?? '',
                email: defaultSuperiorUserResourcePermission?.userInfo.email ?? '',
              },
              userPermission: [defaultUserPermission as AccessPermission],
              assignableUserPermission: defUserAssigPerms,
            },
          })
        }
      } else {
        const currSuperiorUserResourcePermission = superiorUserOptions.find(
          (u) => u.userInfo.orgUserID === state.superior?.orgUserID,
        )
        setSuperior(currSuperiorUserResourcePermission?.userInfo.orgUserID ?? '')
        updateSuperiorInfo &&
          updateSuperiorInfo({
            orgUserID: currSuperiorUserResourcePermission?.userInfo.orgUserID ?? '',
            email: currSuperiorUserResourcePermission?.userInfo.email ?? '',
          })

        const defaultIamRoleOptions = currSuperiorUserResourcePermission?.assignableUserPermission ?? []
        //console.log({ defaultIamRoleOptions })
        setIamRoleOptions(defaultIamRoleOptions)

        const defaultSubOrdniateUsers = currSuperiorUserResourcePermission?.subordinateUsers.length
          ? currSuperiorUserResourcePermission?.subordinateUsers
          : []
        setsubordinateUsersAvail(defaultSubOrdniateUsers)
      }
    }
  }, [superiorUserOptions, editUserId, state.superior, selectedSingleUserLicInfo])

  useEffect(() => {
    if (selectedSingleUserLicInfo && iamRoleOptions.length && state.superior?.orgUserID) {
      const defaultUserPermission =
        selectedSingleUserLicInfo?.licenseTypeName != 'guest'
          ? state.userPermission.length
            ? iamRoleOptions.find((ir) => state.userPermission.some((up) => up.roleID === ir.access.roleID))?.access
            : iamRoleOptions[0].access
          : iamRoleOptions.find((op) => op.access.roleID === 'none-id')?.access
      const defUserAssigPerms =
        iamRoleOptions.find((rl) => rl.access.roleID === defaultUserPermission?.roleID)?.assignPermissionDeps ?? []
      //console.log({ defaultUserPermission }, { defUserAssigPerms })
      dispatch({
        type: ActionType.EditUserSimpleInput,
        payload: {
          ...state,
          userPermission: [defaultUserPermission as AccessPermission],
          assignableUserPermission: defUserAssigPerms.length ? defUserAssigPerms : [],
        },
      })
    }
  }, [selectedSingleUserLicInfo?.licenseTypeID, state.superior, iamRoleOptions.length])

  // useEffect(() => {
  //   if (subOdinateUsers) {
  //     setsubordinateUsersAvail(subOdinateUsers)
  //   }
  // }, [subOdinateUsers])

  // useEffect(() => {
  //   if (iamAppRoleOptions?.length) {
  //     const temp = iamAppRoleOptions.filter((iamx) => iamx.scopes.includes('assign'))
  //     setIamRoleOptions(temp)
  //     const defaultIAMRight = temp.find((rl) => rl.roleName === 'none')
  //     if (state.licenses.some((lt) => lt?.licenseType === 'guest')) {
  //       dispatch({
  //         type: ActionType.UpdateAppStateInput,
  //         payload: {
  //           ...currIAMAppState,
  //           roles: temp.reduce(
  //             (iamAppRole: AsAppRoleInput[], crR) =>
  //               crR.roleName === 'none'
  //                 ? [
  //                     {
  //                       roleName: crR.roleName,
  //                       displayNameKey: crR.displayNameKey,
  //                     },
  //                   ]
  //                 : iamAppRole,
  //             [],
  //           ),
  //           assignableRoles: [],
  //         },
  //       })
  //     }
  //     if (!currIAMAppState.roles.length && state.licenses.some((lt) => lt?.licenseType !== 'guest')) {
  //       const initIAMrole = {
  //         roleName: defaultIAMRight?.roleName ?? temp[0].roleName,
  //         displayNameKey: defaultIAMRight?.displayNameKey ?? temp[0].displayNameKey,
  //       }

  //       const iamRoleRoleDependencies = iamRoleOptions.filter((option) =>
  //         iamRoleOptions.some(
  //           (ro) => initIAMrole.roleName === ro.roleName && ro.includesPermissionsOfRoles?.includes(option.roleName),
  //         ),
  //       )
  //       const reqAssignRoleInps = iamRoleOptions.reduce(
  //         (agg: AsAppRoleInput[], cr) =>
  //           initIAMrole.roleName === cr.roleName
  //             ? iamRoleRoleDependencies.reduce(
  //                 (agg: AsAppRoleInput[], tmpAssignOption) =>
  //                   cr.assignDependencies?.includes(tmpAssignOption.roleName)
  //                     ? [...agg, { roleName: tmpAssignOption.roleName, displayNameKey: tmpAssignOption.displayNameKey }]
  //                     : agg,
  //                 [],
  //               )
  //             : agg,
  //         [],
  //       )
  //       dispatch({
  //         type: ActionType.UpdateAppStateInput,
  //         payload: {
  //           ...currIAMAppState,
  //           roles: [initIAMrole],
  //           assignableRoles: reqAssignRoleInps,
  //         },
  //       })
  //     }
  //   }
  // }, [iamAppRoleOptions, state.superior, state.licenses])

  // useEffect(() => {
  //   if (!subOdinateUsers?.length && superior) {
  //     fetchSubordinates(
  //       superior,
  //       assignedIAMRoleInput,
  //       state.assignableLicenses?.licensePackets?.length
  //         ? (state.assignableLicenses?.licensePackets as ActivatedLicensePacketInput[])
  //         : undefined,
  //     )
  //     //console.log('fetched')
  //   }
  // }, [])

  const changeAssignAccess = (assignAccessIDs: string[]): void => {
    const assignAccessPerms = (superiorOptions as UserResourcePermission[])
      .find((u) => u.userInfo.orgUserID === state.superior?.orgUserID)
      ?.assignableUserPermission.filter((ap) => assignAccessIDs.includes(ap.access.roleID))
    dispatch({
      type: ActionType.EditUserSimpleInput,
      payload: {
        ...state,
        assignableUserPermission: assignAccessPerms?.length ? assignAccessPerms.flatMap((ap) => ap.access) : [],
      },
    })
  }

  const updateSuperior = (usr: string) => {
    setSuperior(usr)

    const superiorUserRes = (superiorOptions as UserResourcePermission[]).find((u) => u.userInfo.orgUserID === usr)
    console.log({ superiorUserRes })
    updateSuperiorInfo &&
      updateSuperiorInfo({
        orgUserID: superiorUserRes?.userInfo.orgUserID ?? '',
        email: superiorUserRes?.userInfo.email ?? '',
      })
    const superiorSubor = superiorUserRes?.subordinateUsers.length ? superiorUserRes?.subordinateUsers : []
    setsubordinateUsersAvail(superiorSubor)
    const selectedSuperiorAccessOptions = superiorUserRes?.assignableUserPermission ?? []
    //console.log({ defaultIamRoleOptions })
    setIamRoleOptions(selectedSuperiorAccessOptions)
    const defaultUserPermission =
      selectedSingleUserLicInfo?.licenseTypeName != 'guest'
        ? selectedSuperiorAccessOptions[0].access
        : selectedSuperiorAccessOptions.find((op) => op.access.roleID === 'none-id')?.access
    const defUserAssigPerms =
      selectedSuperiorAccessOptions.find((rl) => rl.access.roleID === defaultUserPermission?.roleID)
        ?.assignPermissionDeps ?? []

    dispatch({
      type: ActionType.EditUserSimpleInput,
      payload: {
        ...state,
        superior: {
          orgUserID: superiorUserRes?.userInfo.orgUserID ?? '',
          email: superiorUserRes?.userInfo.email ?? '',
        },
        userPermission: [defaultUserPermission as AccessPermission],
        assignableUserPermission: defUserAssigPerms,
        subordinates: [],
      },
    })
    //setFetched(!fetched)
  }

  const updateSubordinates = (users: MinimalUserInfo[]) => {
    dispatch({
      type: ActionType.EditUserSimpleInput,
      payload: { ...state, subordinates: users },
    })
  }

  const changeIAMRole = (values: string) => {
    const tempAppRole = iamRoleOptions.find((cr) => cr.access.roleID === values)
    const assignPerm = tempAppRole?.assignPermissionDeps.map((r) => r.roleID)

    const updatedAssignUserPerm = iamRoleOptions
      .filter((rl) => assignPerm?.includes(rl.access.roleID))
      .flatMap((rl) => rl.access)
    //console.log({ updatedAssignUserPerm })
    setAssignAccessOptions([
      ...updatedAssignUserPerm,
      {
        roleID: tempAppRole?.access.roleID ?? '',
        roleDisplayKey: tempAppRole?.access.roleDisplayKey ?? '',
        roleDescriptionKey: tempAppRole?.access.roleDescriptionKey ?? '',
      },
    ])
    dispatch({
      type: ActionType.EditUserSimpleInput,
      payload: {
        ...state,
        userPermission: [tempAppRole?.access as AccessPermission],
        subordinates: tempAppRole?.access.roleID !== 'none-id' ? state.subordinates : [],
        assignableUserPermission: updatedAssignUserPerm,
        appStates: state.appStates.map((aps) => ({
          ...aps,
          assignableRoles: tempAppRole?.access.roleID !== 'none-id' ? aps.assignableRoles : [],
        })),
      },
    })
  }

  return (
    <>
      <Grid item xs={6} lg={6} container spacing={2} justifyContent="space-between" direction="column">
        {/* <ProgressBar show={!(superiorOptions as UserResourcePermission[]).length} /> */}
        <Grid item xs lg md>
          <Box pr={4}>
            <ActionWithDescription
              heading={t('heading.accessUsers')}
              description={t('descriptions.accessUsers', { user: getUserName(state?.userInfo?.email ?? '') })}
            />
          </Box>
        </Grid>
        <Grid item>
          <TextField
            id={`Superior`}
            label={t('common:labels.superior')}
            variant="outlined"
            margin="none"
            //error={state.subordinates.includes(state.superior as string) || !state.superior}
            //helperText={state.subordinates.includes(state.superior as string) && t('error.subordinateSuperior')}
            type="text"
            select
            fullWidth
            required
            onChange={({ target }) => updateSuperior(target.value as string)}
            name="superior"
            value={`${state.superior?.orgUserID}`}
          >
            {(superiorOptions as UserResourcePermissionExUser[])?.map((superior, idx) => (
              <MenuItem
                key={superior.userInfo.email + idx}
                value={superior.userInfo.orgUserID}
                disabled={superior.isSubordinate}
              >
                {superior.userInfo.email}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {/*  {state.superior && <SelectIamRole superiorName={state.superior} />} */}
        <Grid item>
          <TextField
            id="role-assigable"
            label={t('common:labels.iamEditableRights', { username: getUserName(state?.userInfo?.email ?? '') })}
            select
            type="text"
            fullWidth
            required
            disabled={selectedSingleUserLicInfo?.licenseTypeName === 'guest'}
            onChange={({ target }) => changeIAMRole(target.value)}
            variant="outlined"
            value={state.userPermission?.map((pr) => pr.roleID).join(' ,') ?? ''}
          >
            {iamRoleOptions.map((option) => (
              <MenuItem key={option.access.roleID} value={option.access.roleID}>
                {getTranslatedValue('iam', `${option.access.roleDisplayKey}`, t)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item>
        <CsButtonSecondary
          color="primary"
          onClick={() => {
            setshowSubPermissions(!showSubPermissions)
          }}
        >
          {showSubPermissions ? (
            <KeyboardArrowUpOutlinedIcon fontSize="medium" />
          ) : (
            <KeyboardArrowDownOutlinedIcon fontSize="medium" />
          )}{' '}
          {t('labels.moreSetting')}
        </CsButtonSecondary>
      </Grid>

      <SubordinatePermissions
        show={showSubPermissions}
        accessPermission={state.userPermission.map((rl) => rl.roleID) as string[]}
        subodinateUserOptions={subordinateUsersAvail}
        superiorName={superior}
        assignAccessPermissions={assignAccessOptions}
        licenseType={appLicenseVar.licenseType}
        changeSubordinates={updateSubordinates}
        changeIAMAssignRoles={changeAssignAccess}
        currSubordintates={state.subordinates}
        selectedAssignableLicense={selectedAssignableLicense}
        updatedSelAssignLicense={updatedSelAssignLicense}
      />
    </>
  )
}

export default UserExpertPermissions
