import { Grid, Box, TextField, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AccessPermission,
  ActivatedCloudLicense,
  ActivatedCloudLicenseInput,
  ActivatedLicenseGroup,
  ActivatedLicenseGroupInput,
  ActivatedLicensePacket,
  ActivatedLicensePacketInput,
  ActivatedLicenseType,
  AppStateSimpleInput,
  AssignableLicense,
  AssignableLicenseInput,
  MinimalUserInfo,
  //OrganizationRoleEx,
  UserResourcePermissionEx,
} from '../../../../api/iam.models'
import { ActionWithDescription } from '../../../components/partials'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
//import CheckBoxIcon from '@material-ui/icons/CheckBox'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { getTranslatedValue, getUserName } from '../../../helpers/utils'
import { useAssignLicensePackets, useGetAppAssignableRoles } from '../../../hooks/userHooks'
import { MenuProps } from '../User.Styles'
import { useManageUserContext } from '../UserContext/UserProvider'
import { getOrganization } from '../../../helpers/manageTokens'
import { ActionType } from '../UserContext/userActions'
import { SelectedSingleUserLicense } from '../UserContext/initialState'
//import { CheckBoxOutlined } from '@material-ui/icons'
type SubordinateProps = {
  show: boolean
  subodinateUserOptions: MinimalUserInfo[]
  assignAccessPermissions: AccessPermission[]
  superiorName: string
  accessPermission: string[]
  licenseType: string[]
  currSubordintates: MinimalUserInfo[]
  selectedAssignableLicense: SelectedSingleUserLicense[]
  updatedSelAssignLicense: (asl: SelectedSingleUserLicense[]) => void
  changeSubordinates: (usersSelected: MinimalUserInfo[]) => void

  changeIAMAssignRoles: (assignOptions: string[]) => void
}
const SubordinatePermissions: React.FC<SubordinateProps> = ({
  show,
  subodinateUserOptions,
  assignAccessPermissions,
  accessPermission,
  superiorName,
  licenseType,
  changeSubordinates,
  changeIAMAssignRoles,
  selectedAssignableLicense,
  updatedSelAssignLicense,
  currSubordintates = [],
}: SubordinateProps) => {
  const { state, editUserId, dispatch } = useManageUserContext()
  //const [assignDeps, setAssignDeps] = useState<string[]>()
  const { t } = useTranslation('common')
  //console.log({ subodinateUserOptions })
  const [iamAssignableRoleOptions, setIamAssignableRoleOptions] = useState<AccessPermission[]>([])
  const [assignLicenseTypes, setAssignLicenseTypes] = useState<ActivatedCloudLicenseInput>({
    organizationName: getOrganization(),
  })
  const [assingableCloudLicenses, setAssignableCloudLicenses] = useState<ActivatedCloudLicense>()

  // const iamAssignPermissableRoles = useGetAppAssignableRoles(superiorName, currSubordintates, licenseType)
  const { activatedLicenses } = useAssignLicensePackets(
    superiorName,
    currSubordintates.map((u) => u.orgUserID),
  )
  //console.log(superiorName)
  const defaultselectedLicPacketId = useMemo(() => {
    return state.licenses[0]?.licensePacketID
  }, [state.licenses])
  useEffect(() => {
    if (assignAccessPermissions) {
      setIamAssignableRoleOptions(assignAccessPermissions)
    }
  }, [assignAccessPermissions, currSubordintates, show])

  useEffect(() => {
    if (state.assignableLicenses?.length && !assignLicenseTypes.licensePackets) {
      //console.log(state.assignableLicenses)
      // setAssignLicenseTypes({...assignLicenseTypes,
      //   licensePackets: state.assignableLicenses
      // })
    }
  }, [state.assignableLicenses])

  useEffect(() => {
    if (activatedLicenses?.licensePackets?.length) {
      //console.log({ activatedLicenses })
      const sortedLicPackets = activatedLicenses
      setAssignableCloudLicenses(sortedLicPackets)
      const licPacketToAssign = sortedLicPackets?.licensePackets?.find(
        (packt) => packt?.id === defaultselectedLicPacketId,
      )
      if (
        !assignLicenseTypes.licensePackets &&
        activatedLicenses.licensePackets.some((p) => p?.licenseGroups && p.licenseGroups.some((g) => g?.licenseTypes))
      ) {
        setAssignLicenseTypes({
          organizationName: sortedLicPackets.organizationName,
          licensePackets:
            sortedLicPackets.licensePackets?.length && licPacketToAssign
              ? [
                  {
                    id: licPacketToAssign?.id,
                    packetName: licPacketToAssign?.packetName,
                  },
                ]
              : undefined,
        })
        if (!state.assignableLicenses.length && sortedLicPackets?.licensePackets) {
          const initAssignableLicInpt = sortedLicPackets.licensePackets
            .filter((lp) =>
              state.licenses.some(
                (sl) =>
                  sl.licensePacketID === lp?.id &&
                  lp.licenseGroups?.some(
                    (sg) => sg?.id === sl.licenseGroupID && sg.licenseTypes?.some((st) => st?.id === sl.licenseTypeID),
                  ),
              ),
            )
            .flatMap((ps) =>
              ps?.licenseGroups?.flatMap((pg) =>
                pg?.licenseTypes?.flatMap((pt) => ({
                  licensePacketId: ps.id,
                  licenseGroupID: pg.id,
                  licenseTypeID: pt?.id ?? '',
                })),
              ),
            ) as AssignableLicense[]

          dispatch({
            type: ActionType.UpdateAssingableLicense,
            payload: initAssignableLicInpt,
          })
        }
      }
    }
  }, [activatedLicenses])

  const handleLicPacket = (lPacket: ActivatedLicensePacket): void => {
    const initSelecSingleUserLic: SelectedSingleUserLicense = {
      licensePacketID: lPacket.id,
      licensePacketName: lPacket.packetName,
      licenseGroupID: '',
      licenseGroupName: '',
      licenseTypeID: '',
      licenseTypeKey: '',
      licenseTypeName: '',
    }
    const tmpFilteredLicAssignable: SelectedSingleUserLicense[] =
      selectedAssignableLicense.length && selectedAssignableLicense.some((l) => l.licensePacketID === lPacket.id)
        ? selectedAssignableLicense.filter((lp) => lp.licensePacketID !== lPacket.id)
        : [...selectedAssignableLicense, initSelecSingleUserLic]
    updatedSelAssignLicense(tmpFilteredLicAssignable)

    // const prvPackets: ActivatedLicensePacketInput[] = assignLicenseTypes?.licensePackets?.length
    //   ? (assignLicenseTypes.licensePackets as ActivatedLicensePacketInput[])
    //   : []
    // const filteredPackets = prvPackets.some((pkt) => pkt?.id === lPacket.id)
    //   ? prvPackets.filter((lpfilter) => lpfilter?.id !== lPacket.id)
    //   : [
    //       ...prvPackets,
    //       {
    //         id: lPacket.id,
    //         packetName: lPacket.packetName,
    //       },
    //     ]
    // setAssignLicenseTypes({
    //   ...assignLicenseTypes,
    //   licensePackets: filteredPackets,
    // })
    // dispatch({
    //   type: ActionType.UpdateAssingableLicense,
    //   payload: {
    //     ...assignLicenseTypes,
    //     licensePackets: filteredPackets,
    //   },
    // })
  }

  const handleLicPacketGroup = (packetId: string, lGRoup: ActivatedLicenseGroup): void => {
    const tmpFilteredLicAssignable: SelectedSingleUserLicense[] = selectedAssignableLicense.map((asl) =>
      asl.licensePacketID === packetId && asl.licenseGroupID === lGRoup.id
        ? { ...asl, licenseGroupID: '', licenseGroupName: '' }
        : asl.licensePacketID === packetId
        ? { ...asl, licenseGroupID: lGRoup.id, licenseGroupName: lGRoup.groupname }
        : asl,
    )

    // const tmpFilteredLicAssignable = selectedAssignableLicense.map((lp) => {
    //   if (lp.licensePacketID === packetId) {
    //     if (lp.licenseGroupID === lGRoup.id) {
    //       return { ...lp, licenseGroupID: '', licenseGroupName: '' }
    //     } else {
    //       return { ...lp, licenseGroupID: lGRoup.id, licenseGroupName: lGRoup.groupname }
    //     }
    //   }
    //   return lp
    // })

    updatedSelAssignLicense(tmpFilteredLicAssignable)
    // const prvPacketGroups = assignLicenseTypes.licensePackets?.find((lp) => lp?.id === packetId)?.licenseGroups
    // const currPacketGroups = prvPacketGroups?.length ? prvPacketGroups : []
    // const licGrpInpt: ActivatedLicenseGroupInput = {
    //   groupname: lGRoup.groupname,
    //   id: lGRoup.id,
    //   displayNameKey: lGRoup.displayNameKey,
    //   descriptionKey: lGRoup.descriptionKey,
    // }
    // const filteredLicGrpInpt = currPacketGroups.some((licGrp) => licGrp?.id === licGrpInpt.id)
    //   ? currPacketGroups.filter((filGroup) => filGroup?.id !== licGrpInpt.id)
    //   : [...currPacketGroups, licGrpInpt]
    // setAssignLicenseTypes({
    //   ...assignLicenseTypes,
    //   licensePackets: assignLicenseTypes.licensePackets?.map((lpacket) =>
    //     lpacket?.id === packetId
    //       ? {
    //           ...lpacket,
    //           licenseGroups: filteredLicGrpInpt,
    //         }
    //       : lpacket,
    //   ),
    // })
    // dispatch({
    //   type: ActionType.UpdateAssingableLicense,
    //   payload: {
    //     ...assignLicenseTypes,
    //     licensePackets: assignLicenseTypes.licensePackets?.map((lpacket) =>
    //       lpacket?.id === packetId
    //         ? {
    //             ...lpacket,
    //             licenseGroups: filteredLicGrpInpt,
    //           }
    //         : lpacket,
    //     ),
    //   },
    // })
  }

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleGroupType = (packetID: string, groupID: string, groupTypeInput: ActivatedLicenseType): void => {
  //   const prvLicGrpInput = assignLicenseTypes.licensePackets?.find((lp) => lp?.id === packetID)?.licenseGroups

  //   const prvLicTypes = prvLicGrpInput?.find((lpg) => lpg?.id === groupID)?.licenseTypes

  //   const currLicTypes = prvLicTypes?.length ? prvLicTypes : []
  //   const filteredLicTypes = currLicTypes.some((ltype) => ltype?.id === groupTypeInput.id)
  //     ? currLicTypes.filter((ltype) => ltype?.id !== groupTypeInput.id)
  //     : [
  //         ...currLicTypes,
  //         {
  //           id: groupTypeInput.id,
  //           licenseType: groupTypeInput.licenseType,
  //           displayNameKey: groupTypeInput.displayNameKey,
  //           descriptionKey: groupTypeInput.descriptionKey,
  //           /* licenses: groupTypeInput.licenses?.map((acL) => ({
  //             id: acL?.id ?? '',
  //             reserved: acL?.reserved,
  //             userId: acL?.userId,
  //           })), */
  //         },
  //       ]

  //   setAssignLicenseTypes({
  //     ...assignLicenseTypes,
  //     licensePackets: assignLicenseTypes.licensePackets?.map((lp) =>
  //       lp?.id === packetID
  //         ? {
  //             ...lp,
  //             licenseGroups: lp?.licenseGroups?.map((lpg) =>
  //               lpg?.id === groupID
  //                 ? {
  //                     ...lpg,
  //                     licenseTypes: filteredLicTypes,
  //                   }
  //                 : lpg,
  //             ),
  //           }
  //         : lp,
  //     ) as ActivatedLicensePacketInput[],
  //   })
  //   dispatch({
  //     type: ActionType.UpdateAssingableLicense,
  //     payload: {
  //       ...assignLicenseTypes,
  //       licensePackets: assignLicenseTypes.licensePackets?.map((lp) =>
  //         lp?.id === packetID
  //           ? {
  //               ...lp,
  //               licenseGroups: lp?.licenseGroups?.map((lpg) =>
  //                 lpg?.id === groupID
  //                   ? {
  //                       ...lpg,
  //                       licenseTypes: filteredLicTypes,
  //                     }
  //                   : lpg,
  //               ),
  //             }
  //           : lp,
  //       ) as ActivatedLicensePacketInput[],
  //     },
  //   })
  // }

  const handleSubordinates = (userIDs: string[]): void => {
    const selectedSubordinates = subodinateUserOptions.filter((u) => userIDs.includes(u.orgUserID))
    //console.log({ userIDs }, { selectedSubordinates })
    changeSubordinates(selectedSubordinates)
  }

  return (
    <>
      {show && !accessPermission.some((rl) => rl === 'none-id') && (
        <>
          <Grid item xs={6} lg={show ? 6 : 4} container spacing={2} justifyContent="space-between" direction="column">
            <Grid item>
              <Box pr={4}>
                <ActionWithDescription
                  heading={t('heading.iamRoles')}
                  description={t('descriptions.iamRoles', { user: getUserName(state?.userInfo?.email ?? '') })}
                />
              </Box>
            </Grid>

            <Grid item>
              <TextField
                id={`subordinates`}
                label={t('labels.subordinates')}
                variant="outlined"
                disabled={accessPermission.some((rl) => rl === 'none-id')}
                margin="none"
                type="text"
                select
                fullWidth
                required
                value={currSubordintates.map((u) => u.orgUserID)}
                onChange={({ target }) => handleSubordinates(target.value as unknown as string[])}
                name="subordinates"
                SelectProps={{
                  //value: currSubordintates,
                  multiple: true,
                  MenuProps: MenuProps,
                  //onChange: (value) => changeSubordinates(value as unknown as string[]),
                }}
              >
                {subodinateUserOptions
                  .filter((u) => u.orgUserID !== editUserId)
                  .map((user) => (
                    <MenuItem key={user.orgUserID} value={user.orgUserID}>
                      {user.email}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                id="role-assignable"
                label={t('labels.iamAssignableRights', { username: getUserName(state?.userInfo?.email ?? '') })}
                select
                type="text"
                fullWidth
                name="assignableRoles"
                //disabled={licenseType.includes('guest') || accessPermission.roles.some((rl) => rl?.roleName === 'none')}
                onChange={({ target }) => changeIAMAssignRoles(target.value as unknown as string[])}
                variant="outlined"
                SelectProps={{
                  value: state.assignableUserPermission.map((rl) => rl.roleID),
                  multiple: true,
                  MenuProps: MenuProps,
                }}
              >
                {iamAssignableRoleOptions.map((assignOption) => (
                  <MenuItem
                    key={assignOption.roleID}
                    value={assignOption.roleID}
                    //disabled={assignDeps?.includes(assignOption.roleName)}
                  >
                    {getTranslatedValue('iam', `${assignOption.roleDisplayKey}`, t)}
                    {/* {assignOption.assignRequired
                      ? getTranslatedValue('iam', `${assignOption.roleDisplayKey}`, t) + '*'
                      : getTranslatedValue('iam', `${assignOption.roleDisplayKey}`, t)} */}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </>
      )}
      <Grid item xs={6} lg={show ? 6 : 4} container spacing={2} justifyContent="space-between" direction="column">
        {show && state.userPermission?.some((rl) => rl.roleID !== 'none-id') ? (
          <>
            <Grid item xs={4} lg={6} container spacing={2} justifyContent="flex-start">
              <Grid item>
                <Box pr={4}>
                  <ActionWithDescription heading={t('labels.packetTypes')} description={''} />
                </Box>
              </Grid>
              <Grid item container>
                {assingableCloudLicenses?.licensePackets?.map((lp, i) => (
                  <Grid item container key={`${lp?.id}` + i}>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checkedIcon={
                              selectedAssignableLicense?.some(
                                (lpi) =>
                                  lpi &&
                                  lp &&
                                  lpi?.licensePacketID === lp?.id &&
                                  lp.licenseGroups?.length &&
                                  lp.licenseGroups.some((lpg) => lpg?.id === lpi.licenseGroupID),
                              ) ? (
                                <AddBoxIcon />
                              ) : (
                                <IndeterminateCheckBoxIcon />
                              )
                            }
                            disabled={lp?.forbidden}
                            checked={selectedAssignableLicense.some((asl) => asl.licensePacketID === lp?.id)}
                            onChange={() => lp && handleLicPacket(lp)}
                          />
                        }
                        label={lp?.packetName + `${lp?.required ? ' *' : ''}`}
                      />
                    </Grid>
                    <Grid item container style={{ paddingLeft: '2rem' }}>
                      {selectedAssignableLicense?.some((lcpi) => lcpi?.licensePacketID === lp?.id) &&
                        lp?.licenseGroups?.map((lpg, ix) => (
                          <Grid item container key={`${lpg?.id}` + ix}>
                            <Grid item>
                              <FormControlLabel
                                disabled={!!lpg?.forbidden}
                                control={
                                  <Checkbox
                                    checked={selectedAssignableLicense.some(
                                      (lpc) => lpc.licensePacketID === lp.id && lpg?.id === lpc.licenseGroupID,
                                    )}
                                    onChange={() => lp && lpg && handleLicPacketGroup(lp.id, lpg)}
                                  />
                                }
                                label={t(`license:${lpg?.displayNameKey}`) + `${!!lpg?.required ? ' *' : ''}`}
                              />
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </>
  )
}

export default SubordinatePermissions
