import { makeStyles, Theme, createStyles } from '@material-ui/core'
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: theme.spacing(0, 3),
      width: '100%',
      alignItems: 'center',
    },
    margin: {
      margin: theme.spacing(1),
      minWidth: 300,
      maxHeight: 80,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(0),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0),
      padding: theme.spacing(0),
    },
    dialog: {
      width: '100%',
    },
    textField: {
      minwidth: '25ch',
      fontSize: '12',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    paper: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
      marginBottom: '1rem',
    },
    tree: { flexWrap: 'wrap', paddingLeft: 25 },
    overview: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      border: '1px #1a1a1a solid',
      borderRadius: 4,
    },
  }),
)
const ITEM_HEIGHT = 48
export const ITEM_PADDING_TOP = 8
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getStyles(name: string, chipList: string[], theme: Theme) {
  return {
    fontWeight: chipList.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}
